const ko = {
  안내: '안내',
  아이디: '아이디',
  비밀번호: '비밀번호',
  '로그인 정보를 입력해주세요.': '로그인 정보를 입력해주세요.',
  '아이디 또는 비밀번호를 잘못 입력했습니다.':
    '아이디 또는 비밀번호를 잘못 입력했습니다.',
  '계약이 만료된 계정입니다.': '계약이 만료된 계정입니다.',
  '로그인 중 오류가 발생하였습니다.': '로그인 중 오류가 발생하였습니다.',
  '회원 정보 입력': '회원 정보 입력',
  '나만 아는 비밀번호를 입력해주세요.': '나만 아는 비밀번호를 입력해주세요.',
  '새 비밀번호': '새 비밀번호',
  '비밀번호 확인': '비밀번호 확인',
  '비밀번호는 영문 대소문자/숫자 조합 6자 이상 가능합니다.':
    '비밀번호는 영문 대소문자/숫자 조합 6자 이상 가능합니다.',
  '비밀번호가 일치하지 않습니다.': '비밀번호가 일치하지 않습니다.',
  '새로운 비밀번호로 다시 로그인해주세요!':
    '새로운 비밀번호로 다시 로그인해주세요!',
  '비밀번호 설정 중 오류가 발생하였습니다':
    '비밀번호 설정 중 오류가 발생하였습니다',
  '비밀번호는 대소문자를 포함해 6자이상이여야 합니다.':
    '비밀번호는 대소문자를 포함해 6자이상이여야 합니다.',
  '내 아이디가 맞나요?': '내 아이디가 맞나요?',
  초등학교: '초등학교',
  '현재 초기 비밀번호로 설정되어 있습니다.':
    '현재 초기 비밀번호로 설정되어 있습니다.',
  '비밀번호 변경이 필요합니다.': '비밀번호 변경이 필요합니다.',
  '나만의 닉네임을 만들어주세요.': '나만의 닉네임을 만들어주세요.',
  '닉네임은 2-8글자, 한글/영어/숫자만 가능합니다.':
    '닉네임은 2-8글자, 한글/영어/숫자만 가능합니다.',
  '나만의 닉네임을 확인해주세요.': '나만의 닉네임을 확인해주세요.',
  '사용 가능한 닉네임입니다. 이 닉네임으로 사용하시겠어요?':
    '사용 가능한 닉네임입니다. 이 닉네임으로 사용하시겠어요?',
  '닉네임을 2~8 글자로 설정해주세요.': '닉네임을 2~8 글자로 설정해주세요.',
  '닉네임에 비속어를 사용할 수 없습니다.':
    '닉네임에 비속어를 사용할 수 없습니다.',
  '이미 존재하는 닉네임입니다.': '이미 존재하는 닉네임입니다.'
}

const vt = {
  안내: 'Guide',
  아이디: 'ID',
  비밀번호: 'Password',
  '로그인 정보를 입력해주세요.': 'Vui lòng nhập thông tin đăng nhập.',
  '아이디 또는 비밀번호를 잘못 입력했습니다.': 'ID hoặc mật khẩu không đúng.',
  '계약이 만료된 계정입니다.': 'Tài khoản đã hết hạn.',
  '로그인 중 오류가 발생하였습니다.': 'Lỗi xảy ra khi đăng nhập.',
  '회원 정보 입력': 'Nhập thông tin thành viên',
  '나만 아는 비밀번호를 입력해주세요.': 'Vui lòng nhập mật khẩu chỉ bạn biết.',
  '새 비밀번호': 'Mật khẩu mới',
  '비밀번호 확인': 'Xác nhận mật khẩu',
  '비밀번호는 영문 대소문자/숫자 조합 6자 이상 가능합니다.':
    'Mật khẩu phải có ít nhất 6 ký tự bao gồm chữ và số.',
  '비밀번호가 일치하지 않습니다.': 'Mật khẩu không khớp.',
  '새로운 비밀번호로 다시 로그인해주세요!': 'Đăng nhập lại bằng mật khẩu mới!',
  '비밀번호 설정 중 오류가 발생하였습니다':
    'Lỗi xảy ra khi thiết lập mật khẩu.',
  '비밀번호는 대소문자를 포함해 6자이상이여야 합니다.':
    'Mật khẩu phải có ít nhất 6 ký tự bao gồm chữ hoa và chữ thường.',
  '내 아이디가 맞나요?': 'ID của tôi có đúng không?',
  초등학교: 'Tiểu học',
  '현재 초기 비밀번호로 설정되어 있습니다.':
    'Hiện tại được đặt bằng mật khẩu ban đầu.',
  '비밀번호 변경이 필요합니다.': 'Cần thay đổi mật khẩu.',
  '나만의 닉네임을 만들어주세요.': 'Tạo biệt danh của riêng bạn.',
  '닉네임은 2-8글자, 한글/영어/숫자만 가능합니다.':
    'Biệt danh phải từ 2-8 ký tự, chỉ chữ Hàn/Anh/số.',
  '나만의 닉네임을 확인해주세요.': 'Kiểm tra biệt danh của riêng bạn.',
  '사용 가능한 닉네임입니다. 이 닉네임으로 사용하시겠어요?':
    'Biệt danh có sẵn. Bạn muốn sử dụng biệt danh này không?',
  '닉네임을 2~8 글자로 설정해주세요.': 'Đặt biệt danh từ 2-8 ký tự.',
  '닉네임에 비속어를 사용할 수 없습니다.':
    'Không thể sử dụng từ ngữ thô tục cho biệt danh.',
  '이미 존재하는 닉네임입니다.': 'Biệt danh đã tồn tại.'
}

export { ko, vt }
